.photo {
	padding: 0 200px;
	// display: flex;
	// flex-direction: row;
	// justify-content: center;
	// align-items: flex-start;
	// margin: 0 auto;
	// border: 1px solid black;

	&>.title {
		margin-bottom: 50px;

		@include px900 {
			text-align: center;
			margin: 0;
			// padding-bottom: 50px;
			margin-bottom: 50px;
			width: 100%;
		}
	}
	margin-bottom: 60px;

	@include px900 {
		padding: 0 10px;
	}
}

.photo__items {
	list-style: none;
	margin: 0;
	padding: 0;


}

.photo__items>.slick-next {
	background-image: url(../images/right2.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;	
	width: 58px;
	height: 58px;
	color: transparent;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	position: absolute;
	right: 60px;
	top: -100px;
	
	z-index: 999;
	@include px1024 {
		width: 30px;
		height: 30px;
		top: -40px;
		right: 50%;
		transform: translateX(100%);
	}	
}

.photo__items>.slick-prev {
	background-image: url(../images/left2.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 58px;
	height: 58px;
	color: transparent;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	position: absolute;
	right: 130px;
	top: -100px;
	z-index: 999;
	@include px1024 {
		width: 30px;
		height: 30px;
		top: -40px;
		right: 50%;
		transform: translateX(-100%);
	}
}

.photo__item {
	width: 100%;
}

.photo__subitems {
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
	margin: 0;
	padding: 0;	
}

.photo__subitem {
	margin-bottom: 30px;
	margin-right: 20px;
	width: 30%;
	height: 300px;
	// &:nth-of-type(3n) {
	// 	margin-right: 0;
	// }

	@include px1240 {
		width: 45%;
	}

	@include px900 {
		width: 100%;
		margin-right: 0;
	}	
}

.photo__suburl {
	width: 100%;
}

.photo__subimg {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 300px;
	border-radius: 20px;
	position: relative;
	transition: background-color .5s;
	background-color: transparent;
	&:hover:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#40A5D9, 0.8);
		transition: background-color .5s;
		border-radius: 20px;

	}
	&:hover:after {
		content: url('../images/eye2.png');
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		transition: background-color .5s;
		border-radius: 20px;
	}
	
}