.albums {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.albums__items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
	@include px600 {
		width: 100%;
		padding: 10px;
	}
	
}



.album__item {
	width: 480px;
	height: 370px;
	margin-right: 38px;
	margin-bottom: 38px;
	background-size: cover;
	background-position: 0 0;
	background-repeat: no-repeat;
	border-radius: 20px;
	cursor: pointer;
	&:hover>.albums__url>.album__info {
		display: flex;
	}
	&:hover>.album__url>.album__info {
		display: flex;
	}	

	@include px600 {
		width: 100%;
		height: 350px;
		margin-right: 0;
	}
}

.album__url {
	display: block;
	width: 100%;
	height: 100%;
	text-decoration: none;
}

.albums__url {
	display: block;
	width: 100%;
	height: 100%;
	text-decoration: none;
}

.album__info {
	display: none;
	width: 100%;
	height: 100%;
	background-color: rgba(#40A5D9,0.8);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
}

.album__circle {
	background-color: #FA9DB7;
	border-radius: 50%;
	width: 140px;
	height: 140px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.album_title {
	font-family: 'nunitoregular';
	font-size: 1rem;
	color: white;
	margin: 0;
	padding: 5px 0 0 0;
}
