.banner {
	min-height: 912px;
	width: 100%;
	min-width: 100vw;
	padding-left: 200px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	@include px768 {
		padding: 10px;
		height: auto;
		display: flex;
		flex-direction: center;
		align-items:	center;
		min-height: auto;
		padding-bottom: 50px;
		background-position: 100% 100%;
	}
	
}

.greeting {
	font-family: 'nunitoregular';
	font-size: 1.5rem;
	color: #70747F;
	line-height: 2rem;
	letter-spacing: 0.01rem;
}