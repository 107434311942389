.header {
	width: 100%;
	padding: 15px 48px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	// flex-wrap: wrap;;
	position: relative;
}

.logo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-right: 2px solid #87D3F1;
	padding-right: 10px;
	margin-right: 10px;
	// border-top: 140px solid transparent;
	
}

.logo__image {
	margin-right: 10px;
	width: 77px;
	height: 80px;
	
}

.logo__titles {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.logo__title {
	font-family: 'nunitoblack', Fallback, sans-serif;
	font-style: normal;
	font-weight: 900;
	color: #FFC85B;
	font-size: 1.5rem;
}

.logo__subtitle {
	font-family: 'nunitoblack', Fallback, sans-serif;
	font-weight: bold;
	font-size: 2.25rem;
	text-transform: uppercase;
	color: #87D3F1;
}

.company__name {
	display: block;
	font-family: 'nunitoregular', Fallback, sans-serif;
	min-width: 217px;
	max-width: 217px;
	color: #70747F;
	font-size: 0.6250rem;

	@include px1919 {
		min-width: 160px;
	}

	@include px600 {
		display: none;
	}
}

.menu {
	margin-right: 20px;

	@include px1024 {
		display: none;
	}
}

.mobile-menu {
	display: none;
	cursor: pointer;
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	height: 20px;
	z-index: 99999999999999999999999999999999999999;
	background-color: #fff;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.burger {
	cursor: pointer;
	border-bottom: 4px solid black;
	border-top: 4px solid black;
	width: 30px;
	max-width: 30px;
	min-width: 30px;
	position: relative;
	height: 30px;
	display: block;
	display: none;
	&:before {
		content: '';
		position: absolute;
		width: 30px;
		max-width: 30px;
		min-width: 30px;
		height: 1px;
		border-top: 4px solid black;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	@include px1024 {
		display: block;
	}
}

.menu__items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	list-style: none;
	// display: none;
	padding-left: 20px;
	flex-wrap: wrap;
	background-color: #fff;
}

.mobile__items {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	list-style: none;
	// display: none;
	padding-left: 20px;
	flex-wrap: nowrap;	
	background-color: white;
	width: 100%;
	margin: 0;
	padding: 0;

}

.mobile__item {
	background-color: #fff;
}

.subitem__wrapper {
	display: none;
}

.menu__item {
	padding: 3px 10px 3px 10px;
	margin-right: 5px;
	white-space: nowrap;
	border-radius: 15px;
	transition: background-color .5s;
	transition: color .5s;
	&:hover {
		background-color: #FFC85B;
		transition: background-color .5s;
		&>.menu__url {
			color: white;
			transition: color .5s;
		}

	}
}

.menu__url {
	font-family: 'nunitoextrabold', Fallback, sans-serif;
	font-weight: 800;
	text-transform: uppercase;
	font-size: 0.75rem;
	text-decoration: none;
	color: #12265A;
	white-space: nowrap;
	letter-spacing: 0.05rem;
}

.menu__item_sub {
	position: relative;
	padding: 3px 20px 3px 10px;
	&:after {
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translate(-15px,-50%);
		content: '+';
		font-family: 'nunitoextrabold', Fallback, sans-serif;
		font-weight: 800;
		text-transform: uppercase;
		font-size: 0.75rem;		
		color: #12265A;
		
	}
	&:hover:after {
		color: white;
		content: 'V';
	}

	&:hover>.subitem__wrapper {
		display: flex;
	}
}

.subitem__wrapper {
	position: absolute;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	// background-color: #fff;
	// max-width: 223px;
	z-index: 99999999999999999;

}

.menu__subitems {
	list-style: none;
	padding: 0;
	margin: 22px 0 0 0;
	border: 2px solid #87D3F1;
	border-radius: 20px;
	padding: 15px 15px;
	background-color: #fff;
	// max-width: 223px;
	word-wrap: break-word;
}

.menu__subitem {
	margin-bottom: 15px;
	// max-width: 223px;
	&:nth-last-of-type(1) {
		margin-bottom: 0;
	}
}

.menu__suburl {
	font-family: 'nunitoextrabold';
	font-size: 0.75rem;
	text-transform: uppercase;
	text-decoration: none;
	color: #12265A;
	&:hover {
		color: #FFC85B;
	}
}

.button_spec,.button_nospec {
	background-color: #87D3F1;
	border: 1px solid #87D3F1;
	border-radius: 20px;
	outline: none;
	padding-top: 6px;
	padding-bottom: 6px;
	font-family: 'nunitoblack', Fallback, sans-serif;
	font-weight: 900;
	font-size: 0.76rem;
	text-transform: uppercase;
	text-align: left;
	// text-indent: -33px;
	padding-left: 60px;;
	position: relative;
	max-width: 184px;
	cursor: pointer;
	// white-space: nowrap;
	&:before {
		content: url('../images/eye.png');
		position: absolute;
		padding-right: 10px;
		left: 0;
		top: 50%;
		transform: translate(20px,-50%);
	}
}

.button_nospec {
	display: none;
	min-width: 180px;	
	max-width: 180px;	
	width: 180px;	
}

.button_spec {
	min-width: 180px;	
	max-width: 180px;	
	width: 180px;	

	@include px1024 {
		display: none;
	}
}

.spec {
	display: none;
}

.mm {
	display: flex;
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(white, 0.9);
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
	z-index: 999999999999999999999999999999999999999999999999999;
}

.mm__close {
	width: 30px;
	cursor: pointer;
	margin-bottom: 20px;
}

.mm__items {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.mm__item {
	list-style: none;
	margin: 0 0 10px 0;
	padding: 0;
	width: 100%;

}

.mm__url {
	font-family: 'nunitobold';
	font-size: 1.3rem;
	text-transform: uppercase;
	text-decoration: none;
	border: 1px solid #40A5D9;
	background-color: #40A5D9;
	text-align: center;
	padding: 15px 30px;
	min-width: 90%;
	display: block;
	border-radius: 20px;
	color: white;
	&:hover {
		text-decoration: underline;
	}
}

