.staff__items {
	width: 100%;
	padding: 0 150px;
	margin-top: 64px;
	position: relative;

	@include px1024 {
		padding: 30px;
	}
	
}

.staff__item {
	margin-right: 37px;
	border-radius: 20px;
	background-color: #87D3F1;
	transition: background-color .5s;
	cursor: pointer;
	&:hover {
		background-color: #FFC85B;
		transition: background-color .5s;
	}

	@include px1024 {
		margin-right: 0;
		padding: 0;
	}
}

.staff__item-wrapper {
	display: flex !important;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 54px 90px;
	margin: 0 auto;

	@include px1024 {
		padding: 20px;
	}
	
}

.staff__photo {
	width: 210px;
	height: 210px;
	padding: 5px;
	border-radius: 50%;
	border: 1px solid white;
	margin-bottom: 20px;

	@include px1024 {
		width: 150px;
		height: 150px;
	}
}

.staf__position {
	font-family: 'nunitoextrabold';
	font-size: 1.125rem;
	color: white;
	text-align: center;
}

.staff__name {
	font-family: 'nunitoextrabold';
	font-size: 1.875rem;
	color: white;	
	text-align: center;
}

.staff__info {
	font-family: 'nunitobold';
	font-size: 1rem;
	color: #12265A;	
	text-align: center;
	line-height: 1.625rem;
	letter-spacing: 0.01rem;	
}

.staff__items>.slick-next {
	background-image: url(../images/right.png);
	background-size: cover;
	background-position: 0 0;
	background-repeat: no-repeat;
	width: 80px;
	height: 80px;
	color: transparent;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	position: absolute;
	right: 150px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 999;

	@include px768 {
		right: 40px;
		width: 40px;
		height: 40px		
	}
}

.staff__items>.slick-prev {
	background-image: url(../images/left.png);
	width: 80px;
	height: 80px;
	color: transparent;
	background-size: cover;
	background-position: 0 0;
	background-repeat: no-repeat;	
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	position: absolute;
	left: 100px;
	top: 50%;	
	transform: translateY(-50%);
	z-index: 999;

	@include px768 {
		left: 40px;
		width: 40px;
		height: 40px;
	}
}