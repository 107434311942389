.numbers {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 bottom;
	height: 430px;
	margin-top: -70px;
	position: relative;
	z-index: 0;
	padding-top: 120px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	list-style: none;

	@include px1240 {
		margin-left: 0;
		padding-left: 0;
		flex-wrap: wrap;
		justify-content: center;
		// align-items: flex-start;
		text-align: center;
		// padding-top: 50px;
		// height: 600px;
	}

	@include px600 {
		padding-top: 50px;
		align-items: flex-start;
	}

}

.number {
	margin-right: 70px;
	&:nth-last-of-type(1) {
		margin-right: 0;
	}
	max-width: 160px;

	@include px1240 {
		margin-left: 0;
		margin-right: 20px;
		margin-bottom: 5px;
		max-width: 100%;
	}
}

.number__title {
	font-family: 'nunitoextrabold';
	font-size: 2.5rem;
	color: white;
	margin: 0;
	line-height: 2.0rem;
}

.numver__subtitle {
	font-family: 'nunitoextrabold';
	font-size: 1.125rem;
	color: white;
	margin: 0;
}