@font-face {
	font-family: 'nunitolight';
	src: url('../fonts/nunito-light.eot');
	src: url('../fonts/nunito-light.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/nunito-light.woff2') format('woff2'),
			 url('../fonts/nunito-light.woff') format('woff'),
			 url('../fonts/nunito-light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'nunitomedium';
	src: url('../fonts/nunito-medium.eot');
	src: url('../fonts/nunito-medium.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/nunito-medium.woff2') format('woff2'),
			 url('../fonts/nunito-medium.woff') format('woff'),
			 url('../fonts/nunito-medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'nunitoregular';
	src: url('../fonts/nunito-regular.eot');
	src: url('../fonts/nunito-regular.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/nunito-regular.woff2') format('woff2'),
			 url('../fonts/nunito-regular.woff') format('woff'),
			 url('../fonts/nunito-regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'nunitosemibold';
	src: url('../fonts/nunito-semibold.eot');
	src: url('../fonts/nunito-semibold.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/nunito-semibold.woff2') format('woff2'),
			 url('../fonts/nunito-semibold.woff') format('woff'),
			 url('../fonts/nunito-semibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'nunitoblack';
	src: url('../fonts/nunito-black.eot');
	src: url('../fonts/nunito-black.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/nunito-black.woff2') format('woff2'),
			 url('../fonts/nunito-black.woff') format('woff'),
			 url('../fonts/nunito-black.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;

}




@font-face {
	font-family: 'nunitobold';
	src: url('../fonts/nunito-bold.eot');
	src: url('../fonts/nunito-bold.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/nunito-bold.woff2') format('woff2'),
			 url('../fonts/nunito-bold.woff') format('woff'),
			 url('../fonts/nunito-bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'nunitoextrabold';
	src: url('../fonts/nunito-extrabold.eot');
	src: url('../fonts/nunito-extrabold.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/nunito-extrabold.woff2') format('woff2'),
			 url('../fonts/nunito-extrabold.woff') format('woff'),
			 url('../fonts/nunito-extrabold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'nunitoextralight';
	src: url('../fonts/nunito-extralight.eot');
	src: url('../fonts/nunito-extralight.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/nunito-extralight.woff2') format('woff2'),
			 url('../fonts/nunito-extralight.woff') format('woff'),
			 url('../fonts/nunito-extralight.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}