.maintitle {
	width: 100%;
	height: 280px;
	// background-image: url('../images/header_bg.png');
	background-size: cover;
	background-position: 0 bottom;
	background-repeat: no-repeat;
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	@include px600 {
		height: 240px;
	}
}

.breadcrumbs {
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-family: 'nunitoregular';
	font-size: 1.125rem;
	flex-wrap: wrap;

	@include px600 {
		padding: 0;
		margin-top: 0;
		font-size: 0.9rem;
	}
}

.breadcrumb__url {
	color: #40A5D9;
	// &:after {
	// 	content: '-';
	// 	margin-left: 10px;
	// 	margin-right: 10px;
	// 	text-decoration: none;
	// }
}

.breadcrumb__span {
	color: #70747F;
}

.br-r {
	margin: 0 5px;
	color: rgb(112, 116, 127);
}