.about {
	width: 100%;
	background-image: url('../images/bg_yellow.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 top;
	padding: 150px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	// z-index: 10;

	@include px1240 {
		padding: 120px 0px 0 0;
		flex-direction: column-reverse;
		align-self: center;
	}
}

.about__img-wrapper {
	position: relative;
	margin-right: 132px;
	width: 638px;
	height: 493px;
	background-color: white;
	border-radius: 20px;
	border: 2px solid #FFC85B;	

	@include px1240 {
		border: 2px solid transparent;
		margin: 0;
	}
}

.about__img {
	position: absolute;
	width: 638px;
	height: 493px;	
	left: 60px;
	top: 60px;
	left: 0;
	top: 0;
}

.about-description {
	width: 638px;
	max-width: 50%;
	&>.title {
		margin-bottom: 41px;
	}

	@include px1240 {
		width: 100%;
		padding: 10px;
		max-width: 100%;
		text-align: center;
	}
	
}

.about__info {
	font-family: 'nunitoregular';
	font-size: 1.25rem;
	line-height: 170%;
	letter-spacing: 0.01rem;	
	color: #70747F;
}