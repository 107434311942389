.lastnews {
	//padding: 0 150px;
	max-width: 1440px;
	position: relative;
	margin: 0 auto;

	@include px1240 {
		padding: 0 10px;
	}
}

.news {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;
}

.lastnews__items {
	margin-top: 30px;
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	margin-bottom: 60px;
	flex-wrap: wrap;

	@include px420 {
		padding-left: 0;
		margin-bottom: 10px;
	}

}

.news__items {
	justify-content: space-around;
}

.lastnews__item {

	margin-right: 30px;
	max-width: 440px;
	border: 3px solid #87D3F1;
	padding: 20px 15px;	
	border-radius: 20px;	
	cursor: pointer;
	margin-bottom: 30px;
	&:nth-last-of-type(1) {
		margin-right: 0;
	} 
	&:hover {
		border: 3px solid #FFC85B;
		&>a>button {
			background-color: #FFC85B;
			border: 1px solid #FFC85B;
		}
		&>a>.lastnews__title {
			text-decoration: underline;
		}
	}
	@include px768 {
		margin-right: 0;
	}


}

.new__item {
	max-width: 350px;
	min-width: 280px;
	width: 100%;
	margin-right: 0;
}

.lastnews__url {
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;	
	height: 100%;
}

.lastnews__title {
	font-family: 'nunitoextrabold';
	font-size: 1.5rem;
	color: #12265A;
	
}

.lastnews__shorttext {
	font-family: 'nunitoregular';
	font-size: 1.125rem;
	color: #70747F;
	line-height: 1.5625rem;
	letter-spacing: 0.01rem;	
}

.allnews-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.allnews {
&:hover {
	background-color: #FFC85B;
	border: 1px solid #FFC85B;
}

@include px420 {
	padding: 10px 20px !important;
}
}