* {
	box-sizing: border-box;
}

html {
	font-size: 15px;
	@include px1919 {
		font-size: 14px;
	}

	@include px768 {
		font-size: 13px;
	}
}

body {
	overflow-x: hidden;
}

.wrapper {
	width: 100%;
	overflow-x: hidden;
	position: relative;
	// @include px768 {
	// 	padding: 10px;
	// }
}

.button {
	font-family: 'nunitobold';
	font-size: 1.5rem;
	border-radius: 30px;
	outline: none;
	color: white;
	padding: 7px 15px;

	&_blue {
		background: #87D3F1;
		border: 1px solid #87D3F1;
	}

	&_yellow {
		background: #FFC85B;
		border: 1px solid #FFC85B;
	}

	&_large {
		padding: 15px 70px;
		font-family: 'nunitoextrabold';
	}

	&_small {
		font-size: 0.875rem;
	}

	&_red {
		background-color: #FA9DB7;
		border: 1px solid #FA9DB7;
	}
}

.h1 {
	font-family: 'nunitoblack';
	font-size: 3.75rem;
	color: #12265A;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;

	@include px900 {
		text-align: center;
		font-size: 2.5rem;
	}

	@include px768 {
		text-align: center;
		font-size: 1.8rem;
		text-align: center;
	}
	@include px420 {
		font-size: 2rem;
		word-wrap: break-word;
		white-space: normal;
	}
}

.up {
	display: none;
	background-image: url('../images/up.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100px;
	height: 100px;
	position: fixed;
	top: 100%;
	left: 100%;
	transform: translate(-200%,-200%);
	z-index: 9999999999999999999999999999999999999;
	cursor: pointer;

	@include px768 {
		width: 50px;
		height: 50px;
		transform: translate(-150%,-150%);
	}
}

.title-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.title {
	font-family: 'nunitoblack';
	font-size: 3rem;
	color: #12265A;
	position: relative;
	display: inline-block;
	z-index: 10000;
	margin: 0;
	text-align: center;
}

.first:after {
	content: '';
	position: absolute;
	left: -10px;
	top: 100%;
	transform: translate(0,-100%);
	width: calc(100% + 20px);
	border-bottom: 20px solid rgba(255, 200, 91, 0.6);;
	z-index: -1;
	}

.second:after {
	content: '';
	position: absolute;
	left: -10px;
	top: 100%;
	transform: translate(0,-100%);
	width: calc(100% + 20px);
	border-bottom: 20px solid rgba(250, 157, 183, 0.6);
	z-index: -1;	
}

.third:after {
	content: '';
	position: absolute;
	left: -10px;
	top: 100%;
	transform: translate(0,-100%);
	width: calc(100% + 20px);
	border-bottom: 20px solid rgba(#7BCBF0, 0.6);
	z-index: -1;	
}

.content-wrapper {
	width: 100%;
	max-width: 1500px;
	margin: 60px auto 0 auto;
	font-family: 'nunitoregular';
	padding: 0 10px;
	
	& h1,h2 {
			font-family: 'nunitoblack';
			font-size: 3rem;
			color: #12265A;
			position: relative;
			display: inline-block;
			z-index: 10000;
			margin: 0;
			text-align: center;
			&:after {
				content: '';
				position: absolute;
				left: -10px;
				top: 100%;
				transform: translate(0,-100%);
				width: calc(100% + 20px);
				border-bottom: 20px solid rgba(255, 200, 91, 0.6);;
				z-index: -1;
				}
	}

	& h3 {
		font-family: 'nunitoblack';
		font-size: 2.25rem;
		color: #12265A;
		position: relative;
		display: inline-block;	
	}

	& img {
		padding: 10px;
		border-radius: 20px;
	}

	& a {
		color: #FF7956;
		text-decoration: underline;
	}
	& ul {
		list-style: none;
		margin: 0;
		padding: 0;

	}

	& ul li {
		position: relative;
		padding-left: 40px;
		margin-bottom: 20px;
		&:before {
			content: url('../images/list1.png');
			position: absolute;
			left: 0;
			top: -6px;
	}
	
	}

	& *::selection {
		background-color: #40A5D9;
	}

	& blockquote {
		position: relative;
		padding: 100px 10px 40px 10px;
		font-family: 'nunitoblack';
		font-size: 1.5rem;
		color: #12265A;
		text-align: center;
			&:before {
			content: url('../images/blockquote.png');
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			}
			@include px420 {
				padding: 100px 10px 40px 10px;
				font-size: 1rem;
			} 
	}
	
}

.spec {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	font-family: 'nunitoblack';
	font-size: 1.1rem;
	&>button {
		text-transform: uppercase;
		padding: 5px 10px;
		margin-right: 10px;
		cursor: pointer;
		border-radius: 20px;
		&:nth-last-of-type(1) {
			margin-right: 0;
		
		}
	}
}




