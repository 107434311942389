.director_words {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 bottom;
	height: 430px;
	margin-top: -70px;
	position: relative;
	z-index: 0;
	padding: 0px 200px 0 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include px900 {
		padding: 0 10px;
	}
}

.text {
	font-family: 'nunitoextrabold';
	font-size: 1.375rem;
	color: white;
	text-align: center;

	@include px768 {
		font-size: 1rem;
	}
}

.text_small {
	font-size: 1.125rem;
	margin-bottom: 0;
	margin-top: 0;
}

.director__name {
	margin-top: 0;
	font-family: 'nunitoextrabold';
	font-size: 1.875rem;
	color: white;	

	@include px768 {
		text-align: center;
	}
}
	