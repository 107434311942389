// Общее

@import './includes/vars.scss';
@import './includes/normalize.scss';
@import './includes/media.scss';
@import './includes/general.scss';
@import './includes/fonts.scss';
@import './includes/page.scss';
@import './includes/lightbox.min.css';

// Блоки

@import './blocks/header.scss';
@import './blocks/banner.scss';
@import './blocks/top.scss';
@import './blocks/footer.scss';
@import './blocks/stuff.scss';
@import './blocks/about.scss';
@import './blocks/numbers.scss';
@import './blocks/photo.scss';
@import './blocks/dirwords.scss';
@import './blocks/lastnews.scss';
@import './blocks/maintitle.scss';
@import './pages/albums.scss';
@import './pages/album.scss';



