.footer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	min-height: 500px;
	padding-top: 150px;
	padding-left: 250px;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 0;
	flex-wrap: wrap;

	@include px600 {
		padding: 150px 10px;
		justify-content: center;
		height: auto;
	}
}

.footer>.logo {
	flex-wrap: wrap;
	max-width: 290px;
	border-right: none;
	justify-content: flex-start;
	margin-right: 100px;

	@include px600 {
		margin-right: 0;
		margin-bottom: 30px;
	}
}

.footer>.logo>.company__name {
	display: block;
	width: 100%;
	min-width: 100%;
	color: #FFFFFF;
	font-size: 0.875rem;
	line-height: 1.5rem;	
}

.footer>.logo>.button {
	display: block;
	width: 100%;
	text-align: center;
	font-size: 1.25rem;
}

.footer__contacts {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	@include px600 {
		margin-bottom: 50px;
	}
}

.footer__title {
	font-family: 'nunitoextrabold';
	font-size: 1.5rem;
	color: white;
	margin-top: 0;
}

.footer__contacts-items {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	list-style: none;
	margin: 0;
	padding: 0;
	max-width: 320px;
}

.footer__contacts-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
}

.footer__contacts-img {
	margin-right: 10px;
	min-width: 50px;
	max-width: 50px;
	width: 50px;
}

.footer__contacts-text {
	font-family: 'nunitoregular';
	font-size: 1rem;
	color: white;
	text-decoration: none;
}

.work-clocks_wrapper {
	background: #87D3F1;
	border-radius: 20px;
	padding: 10px 10px;
	min-width: 284px;
}

.work-clocks {
	background: #87D3F1;
	border-radius: 20px;
	padding: 10px;
	border: 1px solid white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px 0;
}

.work-clock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	 padding: 0 10px;
	&>p {
		display: block;
	}
}

.work__text {
	font-family: 'nunitobold';
	font-size: 1rem;
	color: white;
	text-transform: uppercase;
}

.underfooter {
	background-image: url('../images/underfooter.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 bottom;
	min-height: 114px;
	margin-top: -114px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@include px600 {
		display: none;
	}
}

.copyright {
	font-family: 'nunitoregular';
	color: #505D89;
	text-decoration: none;
	font-size: 1.125rem;
	margin-top: 20px;
}